import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css' // 注意要引入css样式文件
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Client/Home'),
        redirect: '/',
        // redirect: '/firstPage',
        children: [
            {
                // path: '/firstPage',
                path: '/',
                name: 'FirstPage',
                component: () => import('../views/Client/FirstPage'),
            },
            {
                path: '/articleDetails',
                name: 'ArticleDetails',
                component: () => import('../views/Client/ArticleDetails'),
            },
            {
                path: '/tag',
                name: 'Tag',
                component: () => import('../views/Client/Tag'),
            },
            {
                path: '/message',
                name: 'Message',
                component: () => import('../views/Client/Message'),
            },
            {
                path: '/chatGPT',
                name: 'ChatGPT',
                component: () => import('../views/Client/ChatGPT'),
            },
            {
                path: '/myHome',
                name: 'MyHome',
                component: () => import('../views/Client/MyHome'),
            },
            {
                path: '/whisper',
                name: 'Whisper',
                component: () => import('../views/Client/Whisper'),
            },
            {
                path: '/toolBox',
                name: 'ToolBox',
                component: () => import('../views/Client/ToolBox'),
            },
            {
                path: '/xiaoCang',
                name: 'XiaoCang',
                component: () => import('../views/Client/XiaoCang'),
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Admin/Login'),
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/Admin/Home'),
        redirect: '/admin/dataDetails',
        children: [
            {
                path: '/admin/dataDetails',
                name: 'DataDetails',
                meta: 'admin',
                component: () => import('../views/Admin/DataDetails'),
            },
            {
                path: '/admin/managementList',
                name: 'ManagementList',
                meta: 'admin',
                component: () => import('../views/Admin/Management'),
            },
            {
                path: '/admin/tagManagement',
                name: 'TagManagement',
                meta: 'admin',
                component: () => import('../views/Admin/TagManagement'),
            },
            {
                path: '/admin/commentManagement',
                name: 'CommentManagement',
                meta: 'admin',
                component: () => import('../views/Admin/CommentManagement'),
            },
            {
                path: '/admin/diaryManagement',
                name: 'DiaryManagement',
                meta: 'admin',
                component: () => import('../views/Admin/DiaryManagement'),
            },
            {
                path: '/admin/leaveManagement',
                name: 'LeaveManagement',
                meta: 'admin',
                component: () => import('../views/Admin/LeaveManagement'),
            },
            {
                path: '/admin/updateMarkdown',
                name: 'UpdateMarkdown',
                meta: 'admin',
                component: () => import('../views/Admin/UpdateMarkdown'),
            },
            {
                path: '/admin/articleEditor',
                name: 'UpdateMarkdown',
                meta: 'admin',
                component: () => import('../views/Admin/ArticleEditor'),
            },
            {
                path: '/admin/friendchain',
                name: 'Friendchain',
                meta: 'admin',
                component: () => import('../views/Admin/Friendchain'),
            },
            {
                path: '/admin/toolBoxManagement',
                name: 'ToolBoxManagement',
                meta: 'admin',
                component: () => import('../views/Admin/ToolBoxManagement'),
            },{
                path: '/admin/xiaocangManagement',
                name: 'XiaocangManagement',
                meta: 'admin',
                component: () => import('../views/Admin/XiaocangManagement'),
            },]
    }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
    routes,
    mode:'history',
    // 切换路由后滚动条置顶
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    },
})


router.beforeEach((to, from, next) => {
    NProgress.start() // 进度条开始
    if (to.meta !== 'admin') {
        next();
    } else {
        let token = window.sessionStorage.getItem('token');
        if (token === null || token === '') {
            next('/login');
        } else {
            next();
        }
    }
})

router.afterEach(() => {
    NProgress.done() // 进度条结束
})

// 进度条的配置项：ease可以设置css3动画，如ease，linear；speed是进度条从开始到结束的耗时
NProgress.configure({
    ease: 'linear',
    speed: 500
});

export default router
